
const moveOverlays = () => {
    const overlaySelector = '.js-ad-reg-overlay';
    const elements = document.querySelectorAll(overlaySelector);
    if (elements.length === 0)
        return;

    const moveOverlay = overlay => document.body.appendChild(overlay);
    const overlays = Array.from(elements);
    overlays.forEach(moveOverlay);
}

const attachEvents = () => {
    attachHelpPinEvents();
}

const attachHelpPinEvents = () => {
    const helpPinSelector = '.js-ad-reg-help';
    const overlayWidth = 300;

    const elements = document.querySelectorAll(helpPinSelector);
    if (elements.length === 0)
        return;

    const onPinClick = (e) => {
        const overlay = document.querySelector(e.target.hash);

        if (!overlay) {
            return;
        }
        let xPos = e.pageX;
        const yPos = e.pageY;

        if (xPos + overlayWidth >= window.innerWidth)
            xPos = (window.innerWidth - overlayWidth) - 50;

        overlay.style.top = `${yPos}px`;
        overlay.style.left = `${xPos}px`;

        e.preventDefault();
    }

    const pins = Array.from(elements);
    pins.forEach(pin => pin.addEventListener('click', onPinClick));
}

const init = () => {
    moveOverlays();
    attachEvents();
}

export default init;
